import { memo } from 'react';
import PropTypes from 'prop-types';
import { useCurrencyInfo } from 'utils/hooks';
import { getFormatPrice, formatAsPercentage } from 'utils/format';

const FieldValue = ({ field }) => {
  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  if ('price' in field)
    return formatAsPrice ? formatAsPrice(field.price) : null;

  if ('percentage' in field)
    return formatAsPercentage(field.percentage, currencyInfo, true, 2);

  return null;
};

FieldValue.propTypes = {
  field: PropTypes.object.isRequired,
};

export default memo(FieldValue);
