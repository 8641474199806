exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Product_stock-value{font-weight:700}.Product_stock-value-placeholder{display:inline-block;height:1.35em;vertical-align:text-bottom;width:6em}.Product_in-stock{color:var(--theme-stockAmount_InStock_Color,#3cb300)}.Product_out-stock{color:var(--theme-stockAmount_OutOfStock_Color,#c00)}.Product_low-stock{color:var(--theme-stockAmount_LowStock_Color,#f28100)}.Product_uom{color:var(--theme-input_Color,#333)}.Product_uom .Product_option{overflow:visible;white-space:normal}.Product_price-per-placeholder,.Product_uom-selector-placeholder,.Product_uom-title-placeholder{display:inline-block;vertical-align:middle}.Product_price-per-placeholder{width:4.2em}.Product_uom-title-placeholder{width:2.5em}.Product_uom-selector-placeholder{height:2em;width:5.6em}.Product_uom-title{word-break:break-word}@supports (-ms-ime-align:auto){.Product_uom-title{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Product_uom-title{word-wrap:break-word}}", ""]);

// exports
exports.locals = {
	"stock-value": "Product_stock-value",
	"stockValue": "Product_stock-value",
	"stock-value-placeholder": "Product_stock-value-placeholder",
	"stockValuePlaceholder": "Product_stock-value-placeholder",
	"in-stock": "Product_in-stock",
	"inStock": "Product_in-stock",
	"out-stock": "Product_out-stock",
	"outStock": "Product_out-stock",
	"low-stock": "Product_low-stock",
	"lowStock": "Product_low-stock",
	"uom": "Product_uom",
	"option": "Product_option",
	"price-per-placeholder": "Product_price-per-placeholder",
	"pricePerPlaceholder": "Product_price-per-placeholder",
	"uom-selector-placeholder": "Product_uom-selector-placeholder",
	"uomSelectorPlaceholder": "Product_uom-selector-placeholder",
	"uom-title-placeholder": "Product_uom-title-placeholder",
	"uomTitlePlaceholder": "Product_uom-title-placeholder",
	"uom-title": "Product_uom-title",
	"uomTitle": "Product_uom-title"
};