import { normalizeBounds, strip } from './helpers';

const defaultUom = {
  quantityStep: 1,
  maximumQuantity: 99999,
};

export const createQuantityModel = (uom, allowEmpty = false) => {
  const {
    quantityStep,
    minimumQuantity,
    maximumQuantity,
    defaultQuantity,
  } = uom || defaultUom;

  const step = quantityStep || defaultUom.quantityStep;
  const minimum = minimumQuantity && minimumQuantity > step
    ? minimumQuantity
    : step;
  const maximum = maximumQuantity || defaultUom.maximumQuantity;

  let initial = normalizeBounds(defaultQuantity || minimum, minimum, maximum);
  initial = strip(Math.round(initial / step) * step);

  return { step, minimum, maximum, initial, allowEmpty };
};

export const adjustForSalesAgreement = (quantityModel, isMaxQuantityEnforced, quantityRemaining) => {
  if (!isMaxQuantityEnforced || quantityRemaining === null ||  quantityRemaining >= quantityModel.maximum)
    return quantityModel;

  const newModel = {
    ...quantityModel,
    maximum: quantityRemaining,
  };

  if (newModel.value > newModel.maximum)
    newModel.value = newModel.maximum;

  return newModel;
};