import { memo } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';

const FieldName = ({ field }) => (
  <RichText textKey={`PriceInfoExtraField_${field.name}`}>
    {field.name}
  </RichText>
);

FieldName.propTypes = {
  field: PropTypes.object.isRequired,
};

export default memo(FieldName);
